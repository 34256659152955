<template>
  <modal-wrapper
    :id="id"
    hide-footer
    hide-header
    size="lg"
    :on-modal-hidden="onClose"
  >
    <template #title>
      {{modalTitle}}
    </template>
    <template #body>
      <quick-pay-wizard
        :modal-id="id"
        :request-bought="requestBought"
      />
    </template>
  </modal-wrapper>
</template>

<script>
import { isNotEmpty } from '@/utils/validators';
import { USER_ANALYSIS_CLASS } from '@/utils/interfaces';

const QuickPayWizard = () => import('./QuickPayWizard.vue');
const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'QuickPayModal',
  components: { QuickPayWizard, ModalWrapper },
  props: {
    id: String,
    title: String,
    requestBought: { ...USER_ANALYSIS_CLASS },
    onClose: Function,
  },
  computed: {
    modalTitle() {
      let titleVar = 'Acquisto prodotto';
      if (isNotEmpty(this.title)) titleVar = this.title;
      return titleVar;
    },
  },
  methods: {},
};
</script>

<style scoped>

</style>
